import $ from 'jquery'
import { find, findAll } from '../helpers/dom'
import { intervalToDuration } from 'date-fns'

const ONE_SECOND = 1000

const setupTimer = (el) => {
  if (el._timerSetupDone) return
  let timeLeft = parseInt(el.dataset.timeLeft)
  let timerOnExpire = el.dataset.timerOnExpire
  el._timerSetupDone = true

  let handleTimerExpiration = () => {
    let [action, actionData] = String(timerOnExpire).split(':')
    switch (action) {
      case 'form_submit':
        if (actionData) {
          const form = find(actionData)
          form && form.submit()
        }
        break
      case 'reload':
        window.location.reload()
        break
      default:
        break
    }
  }

  let countdown = () => {
    timeLeft -= 1

    const { hours, minutes, seconds } = intervalToDuration({
      start: 0,
      end: timeLeft * ONE_SECOND,
    })

    el.innerHTML = `${hours > 0 ? `${hours}:` : ''}${String(minutes).padStart(
      2,
      '0'
    )}:${String(seconds).padStart(2, '0')}`

    if (timeLeft <= 0) {
      handleTimerExpiration()
      return
    }

    setTimeout(countdown, ONE_SECOND)
  }

  setTimeout(countdown, ONE_SECOND)
}

export const setupTimers = () => {
  const timers = findAll('[data-behavior~="timer"]')
  if (timers && timers.length > 0) {
    timers.forEach((timerDom) => {
      setupTimer(timerDom)
    })
  }
}

document.addEventListener('DOMContentLoaded', setupTimers)
$(document).on('simplero:timer:reload', setupTimers)
